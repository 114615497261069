<div class="container">
  <div class="latest-title-container">
    <h6 style="margin-bottom: 0px">Updates 30/11/24 - V21.0</h6>
    <div class="version">Laatste versie mobiele app: V21.0</div>
  </div>
  <ul>
    <li><b>Nieuwe design en bijwerkingen website</b></li>
    <li><b>Mogelijkheid om aansluitingen te verplaatsen van project</b></li>
  </ul>
  <h6 style="font-weight: 500;  border-bottom: 2px solid #3498db;">Update 15/11/24 - Mobiele app V21.0</h6>
  <ul>
    <li><b>Bug fix:</b> Probleem opgelost waarbij data kon verdwijnen wanneer meerdere mobiele gebruikers aan dezelfde aansluiting werkten</li>
  </ul>
  <h6 style="font-weight: 500;  border-bottom: 2px solid #3498db;">Updates 13/10/24 - V19.1</h6>
  <ul>
    <li>Standaardwaarden instelbaar per project: waarden worden overgenomen bij aanmaak van nieuwe aansluitingen of kolken(mobile & website)</li>
    <li>Mobiele app: mogelijkheid om dezelfde foto te gebruiken voor DWA en RWA</li>
    <li>Algemene verbeteringen, design en functionaliteit</li>
  </ul>
  <h6 style="font-weight: 500;  border-bottom: 2px solid #3498db;">Updates 13/07/24 - V18.1</h6>
  <ul>
    <li>Nieuwe schademeldingsmodule</li>
    <li>Straatsuggesties per project nu ook zichtbaar bij het creëeren van nieuwe huisaansluitingen,onvoorziene werken,schademeldingen en de kolken in de mobiele app</li>
    <li>Nieuwe taal mobiele app: Pools</li>
    <li>Bulk wijzigen: meerdere huis-/wachtaansluitingen of kolken tegelijk wijzigen</li>
  </ul>
  <h6 style="font-weight: 500; border-bottom: 2px solid #3498db;">Aanpassingen aan de KOLK volgens AWV update 13/07/24 - V18.1</h6>
  <ul>
    <li>Kolk : Buis opgesplitst in buis horizontaal en buis verticaal -><br>Bij kolken vóór 13/07/24 waarbij geen informatie is ingevuld bij buis verticaal, zal op het PDF-formulier de buis zonder opsplitsing worden weergegeven.
      <span class="note"><br>(in de excels en andere pagina's worden de huidige waardes getoond bij buis horizontaal)</span>
    </li>
    <li>Nieuwe waarde: Type kolk : 'Beton' of 'Gietijzer'</li>
    <li>Aanpassing waarde: 'Aansluiting op open gracht' wordt -><br>'Aansluiting op vrije uitstroom? (bufferbekken/gracht/wadi)'</li>
    <li>Voor projecten in opdracht van AWV: bij KOLK: 2 extra schetsen + 2 verschillende lambertcoördinaten verplicht. AID-nummer & Ident8 instelbaar op de werf</li>
  </ul>
</div>
