import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import {Company} from "../models/company";
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  public thisCompany: Company;
  constructor(
    private authService: NbAuthService,
    private router: Router,
    private apiService: ApiService,
  ) // private userInfoService: UserInfoService
  {}

  isAdmin: boolean;

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      // @ts-ignore
      tap(async (authenticated) => {
        if (!authenticated) {
          this.router.navigate(['auth/login']);
        } else {
          return true;
        }
      }),
    );
  }
}
