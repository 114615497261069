<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Se<span>lux</span> riolering</a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <img ngSrc="assets/images/selux_groot.png" style="width: 50px;height:50px" alt="logo" height="1000" width="1000">
  <p *ngIf="isNewUrlFirstWeek" style="margin:0 0 0 40px !important;height:20px">Nieuwe URL: <b>selux-riolering.com</b></p>

</div>

<div *ngIf="currentGroup" class="header-container">
  <div  class="project-wrapper">
    <button mat-button
            [matMenuTriggerFor]="projectDetails"
            class="municipality-btn">
      <div class="project-content">
        <span class="project-name">{{ currentGroup?.rbGemeente }}</span>
        <span class="separator">|</span>
        <span class="project-details">
        <span class="project-number">{{ currentGroup?.aannemerProjectNr }}</span>
          <span class="separator">|</span>
        <span class="project-name">{{ currentGroup?.rbProjectNaam }}</span>
      </span>
      </div>
    </button>
  </div>

  <mat-menu #projectDetails="matMenu" class="project-details-menu">
    <div mat-menu-item class="menu-content" (click)="$event.stopPropagation()">
      <div class="info-grid">
        <div class="info-item">
          <p class="label">Projectnaam<br>rioolbeheerder</p>
          <span class="value">{{currentGroup?.rbProjectNaam}}</span>
        </div>
        <div class="info-item">
          <p class="label">Gemeente</p>
          <span class="value">{{currentGroup?.rbGemeente}}</span>
        </div>
        <div class="info-item">
          <p class="label">Projectnr.<br>aannemer</p>
          <span class="value">{{currentGroup?.aannemerProjectNr}}</span>
        </div>
        <div class="info-item">
          <p class="label">Werfleider</p>
          <span class="value">{{currentGroup?.aannemerWerfleider}}</span>
        </div>
        <div style="margin-top: 15px" class="info-item">
          <span class="label">Gebruikers</span>
          <div *ngIf="currentGroup?.users?.length; else noUsers" class="nameFlex">
            <p *ngFor='let user of currentGroup?.users' class='user'>{{user.name}}</p>
          </div>

          <ng-template #noUsers>
            <span class="value">Geen mobiele werkers gekoppeld</span>
          </ng-template>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

<div class="header-container">

  <nb-actions style='margin-right: 30px;' size="small">
    <nb-action style="padding:0 !important;" class="control-item">
      <div style="position: relative; display: inline-block;">
        <button nbButton status="basic" style="background-color: #d6e6ff" (click)="openUpdateWindow()">
          Updates
        </button>
        <nb-badge *ngIf="isRecentNewUpdate" text="nieuw" status="success" position="top right" style="position: absolute; top: -10px; right: -10px;"></nb-badge>
      </div>

    </nb-action>
  </nb-actions>

  <nb-actions size="small">
    <nb-action class="control-item">
      <button nbButton outline status="danger" (click)="onLogoutClick()">
        afmelden
      </button>
    </nb-action>

  </nb-actions>
</div>
