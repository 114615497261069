import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { UpdatePopupDialogComponent } from '../../../pages/update-popup-dialog/update-popup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import * as Sentry from "@sentry/angular";
import { ApiService } from '../../../../services/api.service';
import { Group } from '../../../../models/groups';
import { User } from '../../../../models/user';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  isRecentNewUpdate: boolean = false;
  updateDate: Date = new Date(2024,9,11,0,0)

  isNewUrlFirstWeek: boolean = false;
  currentGroup: Group;
  showProjectDetails: boolean = false;
  private subscription: Subscription;
  firstUser: string;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private authService: NbAuthService,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.subscription = this.apiService.headerData$.subscribe(
      data => {
        if (data) {
          this.currentGroup = data;
          if (this.currentGroup.users && this.currentGroup.users.length > 0) {
            if (this.currentGroup.users[0].name == null || this.currentGroup.users[0].name === '') {
              this.firstUser = this.currentGroup.users[0].email;
            } else {
              this.firstUser = this.currentGroup.users[0].name;
            }
            this.currentGroup.users = this.currentGroup.users.filter(x => x.name !== "bjorn programmeur selux");
            console.log(this.currentGroup);
          }
        } else {
          this.currentGroup = null;
        }
      }
    );
    //use moment and look if its older than 1 month
    let threeDayUpdateShow = moment(this.updateDate).add(3, 'days').toDate();

    if(threeDayUpdateShow.getTime() > new Date().getTime()){
      this.isRecentNewUpdate = true;
    } else {
      this.isRecentNewUpdate = false;
    }

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  toggleProjectDetails() {
    this.showProjectDetails = !this.showProjectDetails;
  }
  onLogoutClick() {
    this.authService.logout('email');
    localStorage.removeItem('auth_app_token');
    Sentry.setUser(null);
    this.router.navigate(['/auth/login']);
  }
  openUpdateWindow() {
    const dialogRef = this.dialog.open(UpdatePopupDialogComponent, {
      height: '674px',
      width: '750px',

    });
  }
}
